<template>
  <div class="fake-nav h-16 px-6 fixed top-0 backdrop-blur-md backdrop-saturate-150"></div>
  <Disclosure as="nav" class="z-10 topnav shadow fixed top-0 inset-x-0" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="absolute -inset-0.5" />
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center mr-2">
            <router-link as="a" :to="'/'"  class="">
              <img class="h-8 mt-1 w-auto" src="/xrl_text_logo_small.png" alt="XRL Logo" />
            </router-link>
          </div>
          <!-- I AM TROUBLE BELOW -->
          <div class="hidden sm:ml-4 sm:flex sm:space-x-4 md:space-x-6">
            <!-- <div class="absolute backdrop-blur-md bg-red-500 bg-opacity-50 " style="height: 600px; width: 100px; z-index: 900;"></div> -->
            <!-- <span class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700"><router-link to="/about">About</router-link></span> -->
            <span v-for="item in menu" :key="item.label" class="root-menu-item inline-flex items-center border-b-2 border-transparent hover-transition-2 px-2 pt-1 text-sm font-medium hover:border-glass-primary-500">
              <div class="backlight"></div>
              <Popover class="relative" v-if="item.children" :open="is_leagues_open" @update:open="is_leagues_open = $event">
                <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-glass-white-700">
                  <div class="uppercase">
                    <img class="h-10 w-10 rounded-full inline-flex mr-3" :src="$root.logoFor($root.game_name)" />
                    <span>Leagues</span>

                    <ChevronDownIcon class="inline-flex -mr-1 w-5 text-glass-white-500" aria-hidden="true" />
                  </div>
                </PopoverButton>

                <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                  <PopoverPanel v-slot="{ close }" :class="is_leagues_open ? '' : '' " class="absolute left-1/2 z-10 mt-1 flex w-screen max-w-min -translate-x-1/3 px-2 py-2 games-nav nav-menu-white-glass-1 shadow-lg overflow-y-scroll">
                    <div class="flex flex-col h-full">
                      <div class="w-56 menu-inner shrink text-sm font-semibold text-gray-900 ring-1 ring-gray-900/5 shadow-sm ">
                        <div :class="item.class" v-for="item in item.children" :key="item.label" :to="item.href" class="menu-item  logo">
                        <!-- <div :class="item.class" v-for="item in item.children" :key="item.label" :to="item.href" class="menu-item item-hover-4 hover-transition-4 logo"> -->
                          <router-link :class="item.class" as="a" :to="item.href"  class="block p-2 pl-2 flex items-center item-hover-4 hover-transition-2 rounded-md " @click="close">
                            <div class="game-logo-wrapper inline-flex mr-2">
                              <img class="h-10 w-10 rounded-full shadow-lg object-cover" :src="require(`@/assets/images/icons/games/${item.class}.png`)" />
                            </div>
                            <div class="game-name text-md font-aldrich pr-2">{{item.label}}</div>
                          </router-link>
                        </div>
                      </div>
                      <!-- weird bug workaround -->
                      <div class="pb-2"></div>
                    </div>
                  </PopoverPanel>
                </transition>
              </Popover>
            </span>

            <template v-if="$root.in_league">
              <span v-for="item in league_menu" :key="item.label" class="root-menu-item inline-flex items-center border-b-2 border-transparent px-2 pt-1 text-sm font-medium hover:border-glass-primary-500">
                <div class="backlight"></div>
                <Popover class="relative" v-if="item.children" :open="item.is_open" @update:open="item.is_open = $event">
                  <PopoverButton class="inline-flex items-center gap-x-0 text-sm font-semibold leading-6 text-glass-white-700">
                    <span class="uppercase">
                      {{item.label}}
                      <ChevronDownIcon class="inline-flex -mr-1 w-5 text-glass-white-700" aria-hidden="true" />
                    </span>
                  </PopoverButton>

                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                    <PopoverPanel v-slot="{ close }" class="absolute left-1/2 z-10 mt-1 flex w-screen max-w-min -translate-x-1/2 px-2 py-2 nav-menu-white-glass-1">

                      <div class="w-56 menu-inner shrink text-sm font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5 ">
                        <div :class="child.class" v-for="child in item.children" :key="child.label" :to="child.href" class="menu-item item-hover-1 hover-transition-3 logo hover:text-white">
                          <router-link :class="child.class" as="a" :to="leaguePathFor(child.href)"  class="block p-2 pl-9 inline-flex items-center" @click="close">
                          <div class="text-md font-aldrich pr-2">{{child.label}}</div>
                        </router-link>
                      </div>
                    </div>
                  </PopoverPanel>
                </transition>
              </Popover>
            </span>
            </template>

            <template v-if="!$root.in_league">
              <span v-for="item in xrl_menu" :key="item.label" class="root-menu-item inline-flex items-center border-b-2 border-transparent px-1 sm:px-2 pt-1 text-sm font-medium hover:border-glass-primary-600">
                <div class="backlight"></div>
                <Popover class="relative" v-if="item.children" :open="item.is_open" @update:open="item.is_open = $event">
                  <PopoverButton class="inline-flex items-center gap-x-0 text-sm font-semibold leading-6 text-glass-white-700">
                    <span class="uppercase">
                      {{item.label}}
                      <ChevronDownIcon class="inline-flex -mr-1 w-5" aria-hidden="true" />
                    </span>
                  </PopoverButton>

                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                    <PopoverPanel v-slot="{ close }" class="absolute left-1/2 z-10 mt-1 flex w-screen max-w-min -translate-x-1/2 px-2 py-2 nav-menu-white-glass-1">

                      <div class="w-56 menu-inner shrink text-sm font-semibold text-gray-900 shadow-lg ring-1 ring-slate-900/5 ">
                        <div :class="child.class" v-for="child in item.children" :key="child.label" :to="child.href" class="menu-item item-hover-1 hover-transition-3 logo hover:text-white">

                          <router-link :class="child.class" as="a" :to="child.href"  class="block p-2 pl-9 inline-flex items-center" @click="close">
                            <!-- <div class="game-logo-wrapper inline-flex mr-1">
                            <img class="h-10 w-10 rounded-full" :src="require(`@/assets/images/icons/games/${item.class}.png`)" />
                          </div> -->

                          <div class="text-md font-aldrich pr-2">{{child.label}}</div>
                        </router-link>
                      </div>
                    </div>
                  </PopoverPanel>
                </transition>
              </Popover>
            </span>
          </template>
          </div>
        </div>

        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <SignedOut v-if="!$root.signed_in" />

          <Notifications v-if="$root.signed_in">
          </Notifications>

          <!-- Profile dropdown -->
          <SignedIn v-if="$root.signed_in">
          </SignedIn>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pb-4 pt-2">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <DisclosureButton as="a" href="#" class="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700">Dashboard</DisclosureButton>
        <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Team</DisclosureButton>
        <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Projects</DisclosureButton>
        <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Calendar</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
  Popover, PopoverButton, PopoverPanel
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import SignedIn from './top_nav/signed_in.vue';
import SignedOut from './top_nav/signed_out.vue';
import Notifications from './top_nav/notifications.vue';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import { parameterize } from '@/lib/helpers';

export default {
  name: 'TopNav',
  mixins: [TemplateHelpersMixin],
  components: {
    Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
    Bars3Icon, BellIcon, XMarkIcon, SignedIn, Notifications, ChevronDownIcon,
    Popover, PopoverButton, PopoverPanel, SignedOut
  },
  methods: {
    //todo: this was worknig but now isn't
    close(e) {
      if(e.target) {
        e.target.click();
      }
      console.log('clicked close');
      this.$root.toggleOverlay();
    }
  },
  async created() {
    let leagues_items = _.map(_.filter(_.sortBy(this.$root.leagues, 'created_at'), (l) => { return !l.hide_from_nav}), (league) => {
      return {label: league.display_name, href: `/leagues/${league.subdomain}`, class: league.subdomain, src: `/public/images/icons/games/${league.subdomain}.png`}
    })

    // leagues_items.unshift({label: 'XRL', href: '/players/region/na', class: 'xrl'});
    this.menu.push({label: 'Leagues', children: ref(leagues_items) });
    window.topnav = this;
  },
  setup() {
    return {
      menu: ref([]),
      xrl_menu: ref([
        {
          href: 'players',
          label: 'Players',
          class: '',
          is_open: ref(false),
          children: ref([
            {href: '/players/region/na', label: 'North America'},
            {href: '/players/region/eu', label: 'Europe'},
            {href: '/players/region/apac', label: 'Asia / Pacific'}
          ])
        }
      ]),
      league_menu: ref( [
        {
          href: 'standings',
          label: 'Standings',
          class: '',
          is_open: ref(false),
          children: ref([
            {href: 'tournaments', label: 'Tournaments'},
            {href: 'region/na', label: 'North America'},
            {href: 'region/eu', label: 'Europe'},
            {href: 'region/apac', label: 'Asia / Pacific'}
          ])
        },
        {
          href: 'players',
          label: 'Players',
          class: '',
          is_open: ref(false),
          children: ref([
            {href: 'players/region/all', label: 'ALL'},
            {href: 'players/region/na', label: 'North America'},
            {href: 'players/region/eu', label: 'Europe'},
            {href: 'players/region/apac', label: 'Asia / Pacific'}
          ])
        }
      ]),
      is_leagues_open: ref(true)
    }
  }
}
</script>

<style>
.fake-nav {
  width: 100%;
  z-index: 5;
}

.game-logo-wrapper {
  /* @apply bg-gradient-to-b from-glass-accent-600 to-glass-accent-200 rounded-full; */
}

.games-nav {
  height: 300px;
}
</style>
